import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../Components/Layout";
import CourseCard from "../common/CourseCard/CourseCard";
import Section from "../common/Section/Section";
import Container from "react-bootstrap/Container";

import { basicAdultsData, aquaGymData } from "../utils/content";

const links = ["コース", "大人コース"];

const AdultsCoursePage = () => {
  const data = useStaticQuery(graphql`
    {
      prismicAdultsCourse {
        id
        data {
          aquagym_image {
            url
          }
          aquagym_text {
            text
            html
          }
          basic_image {
            url
          }
          basic_text {
            html
            text
          }
        }
      }
    }
  `);

  const fullBasicAdultsData = {
    ...basicAdultsData,
    image_url: data.prismicAdultsCourse.data.basic_image.url,
    text: data.prismicAdultsCourse.data.basic_text.text,
  };

  const fullAquagymData = {
    ...aquaGymData,
    image_url: data.prismicAdultsCourse.data.aquagym_image.url,
    text: data.prismicAdultsCourse.data.aquagym_text.text,
  };

  return (
    <>
      <Layout
        links={links}
        title="Adults"
        subTitle="大人コース"
        seoTitle="大人コース"
        seoDescrption="成人向けコースの総詳細ページです。（レディースコース、成人コース、水中運動コース）"
        seoKeywords="レディースコース,成人コース,水中運動コース,所沢,狭山市"
      >
        <Section>
          <Container>
            <CourseCard page={fullBasicAdultsData} />
          </Container>
        </Section>

        <Section grey>
          <Container>
            <CourseCard page={fullAquagymData} />
          </Container>
        </Section>
      </Layout>
    </>
  );
};

export default AdultsCoursePage;
